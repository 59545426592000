import { ref } from '@vue/composition-api'
import { sprintf } from 'printj'
import {
  cloneNested, getDecimals, getIntegers, parseRequestError,
} from '@/helpers/helpers'
import store from '@/store'

export default () => {
  const settlements = ref([])

  store.dispatch('customers/fetchCustomerSettlements')
    .then(data => {
      const localData = cloneNested(data)
      data.forEach((settlement, index) => {
        localData[index].balanceHistory = []
        localData[index].balanceHistoryLoading = false
        localData[index].paymentAmount = 0.00
      })
      settlements.value = localData
    })
    .catch(err => parseRequestError(err))

  const fetchPaymentDetailsData = (customerId, settlementsIndex) => {
    settlements.value[settlementsIndex].balanceHistory = []
    settlements.value[settlementsIndex].balanceHistoryLoading = true
    store.dispatch('customer/fetchCustomerBalanceHistories', { customerId })
      .then(res => {
        settlements.value[settlementsIndex].balanceHistory = res.data
      })
      .catch(err => parseRequestError(err))
      .finally(() => {
        settlements.value[settlementsIndex].balanceHistoryLoading = false
      })
  }

  const getPaymentTitle = settlement => `ID-${settlement.customer.id} ${settlement.customer.lastName} ${settlement.customer.firstName}`.substring(0, 32)

  const getQrCodeContent = settlement => {
    if (!settlement.organization.accountNumber || !settlement.organization.address) {
      return null
    }

    const paymentAmount = Math.round(settlement.paymentAmount * 100)
    if (paymentAmount > 999999) {
      return null
    }

    let taxIdentifier = ''
    if (settlement.organization.address.taxIdentifier) {
      taxIdentifier = settlement.organization.address.taxIdentifier
        .replace('|', ' ')
        .substring(0, 10)
    }

    //  https://zbp.pl/getmedia/1d7fef90-d193-4a2d-a1c3-ffdf1b0e0649/2013-12-03_-_Rekomendacja_-_Standard_2D
    return [
      taxIdentifier,
      settlement.organization.accountCountry,
      settlement.organization.accountNumber.replace('|', ' ').replace(/\D/g, '').substring(0, 26),
      sprintf('%06d', paymentAmount),
      settlement.organization.address.companyName.replace('|', ' ').substring(0, 32),
      getPaymentTitle(settlement).replace('|', ' '),
      '',
      '',
      '',
    ].join('|')
  }

  const prepareBalanceAmount = settlement => settlement.statistics.incomeToPay - settlement.statistics.customerBalance

  return {
    settlements,
    getIntegers,
    getDecimals,
    fetchPaymentDetailsData,
    getPaymentTitle,
    getQrCodeContent,
    prepareBalanceAmount,
  }
}
