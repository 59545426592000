<template>
  <div>
    <b-row>
      <b-col
        v-for="(settlement, index) in settlements"
        :key="index"
        cols="12"
        xl="10"
        offset-xl="1"
      >
        <b-card no-body>

          <template #header>
            <h3>{{ settlement.organization.address.companyName }}</h3>
            <h4
              v-if="me.roles.includes(role.guardian)"
              class="text-muted"
            >
              {{ settlement.customer.lastName }} {{ settlement.customer.firstName }}
            </h4>
          </template>

          <b-row class="match-height mx-1 mb-1">
            <b-col
              cols="12"
              md="6"
              class="mb-1"
            >
              <b-row>
                <b-col class="text-center">
                  <b-avatar
                    size="48"
                    variant="success"
                  >
                    <feather-icon
                      size="24"
                      icon="DollarSignIcon"
                    />
                  </b-avatar>
                  <div class="font-weight-bolder mb-0 mt-50 font-large-1">
                    {{ getIntegers(Math.abs(prepareBalanceAmount(settlement))) }}<span
                      class="font-medium-4 text-muted"
                    >.{{ getDecimals(prepareBalanceAmount(settlement)) }} zł</span>
                  </div>
                  <b-card-text class="font-medium-1 mb-0">
                    <span v-if="prepareBalanceAmount(settlement) >= 0">
                      {{ $t('customers.statistics.to-pay') }}
                    </span>
                    <span v-else>
                      {{ $t('customers.statistics.overpayment') }}
                    </span>
                  </b-card-text>
                </b-col>
                <b-col
                  v-if="settlement.customer.discountPercent"
                  class="text-center"
                >
                  <b-avatar
                    size="48"
                    variant="info"
                  >
                    <feather-icon
                      size="24"
                      icon="PercentIcon"
                    />
                  </b-avatar>
                  <div class="font-weight-bolder mb-0 mt-50 font-large-1">
                    {{ settlement.customer.discountPercent }}%
                  </div>
                  <b-card-text class="font-medium-1 mb-0">
                    {{ $t('customers.statistics.discount') }}
                  </b-card-text>
                </b-col>
              </b-row>
            </b-col>
            <b-col
              cols="12"
              md="6"
              class="mb-1"
            >
              <h3 class="section-label">
                {{ $t('customer.settlements.meeting-statistics') }}
              </h3>
              <dl class="row mb-0">
                <dt class="col-sm-9">
                  {{ $t('customers.statistics.meetings-planned') }}
                </dt>
                <dd class="col-sm-3">
                  {{ settlement.statistics.plannedMeetings }}
                </dd>
              </dl>
              <dl class="row mb-0">
                <dt class="col-sm-9">
                  {{ $t('customers.statistics.meetings-ended') }}
                </dt>
                <dd class="col-sm-3">
                  {{ settlement.statistics.pastMeetings }}
                </dd>
              </dl>
              <dl class="row mb-0">
                <dt class="col-sm-9">
                  {{ $t('customers.statistics.absences') }}
                </dt>
                <dd class="col-sm-3">
                  {{ settlement.statistics.customerAbsenceMeetings }}
                </dd>
              </dl>
              <dl class="row mb-0">
                <dt class="col-sm-9">
                  {{ $t('customers.statistics.cancellations') }}
                </dt>
                <dd class="col-sm-3">
                  {{ settlement.statistics.customerCanceledMeetings }}
                </dd>
              </dl>

            </b-col>
          </b-row>

          <rc-overlay
            :show="!!settlement.balanceHistoryLoading"
            no-fade
            variant="white"
          >
            <b-collapse
              :id="`payment-details-${index}`"
              @show="fetchPaymentDetailsData(settlement.customer.id, index)"
            >
              <div
                v-if="settlement.balanceHistory.length"
                class="h-100"
              >
                <h4 class="m-1">
                  {{ $t(`customer.settlements.balance-history-details`) }}
                </h4>
                <balance-history-table :customer-balance-histories="settlement.balanceHistory" />
              </div>
              <div
                v-else-if="settlement.balanceHistory.length === 0 && !settlement.balanceHistoryLoading"
                class="alert alert-info p-1 text-center mb-1 mx-1"
              >
                {{ $t(`customers.tabs.settlements.no-balance-history`) }}
              </div>
            </b-collapse>
          </rc-overlay>

          <template #footer>
            <div class="float-right">
              <b-button
                v-b-toggle="[`payment-details-${index}`]"
                variant="flat-primary"
              >
                <span class="when-open">{{ $t('customer.settlements.hide-details') }}</span>
                <span class="when-closed">{{ $t('customer.settlements.show-details') }}</span>
              </b-button>
              <div
                v-b-tooltip
                class="d-inline-block ml-1"
                :title="!settlement.organization.hasPaymentIntegration ? $t('customer.settlements.payment-inactive') : null"
              >

                <b-button
                  :to="{ name: 'customer-settlements-pay', params: { customerId: settlement.customer.id }}"
                  :disabled="!settlement.organization.hasPaymentIntegration"
                  variant="primary"
                  :class="{'cursor-not-allowed': !settlement.organization.hasPaymentIntegration}"
                >
                  {{ $t('customer.settlements.add-payment') }}
                </b-button>
              </div>
            </div>
          </template>
        </b-card>

        <b-modal
          :id="`add-payment-modal-${index}`"
          size="lg"
          :title="$t('customer.settlements.add-payment')"
        >
          <b-row class="my-1">
            <b-col
              cols="12"
              md="7"
            >
              <h4 class="mb-2">
                {{ $t('customer.settlements.add-payment-modal.bank-transfer-details') }}
              </h4>

              <dl class="row mb-50">
                <dt class="col-sm-4">
                  {{ $t('customer.settlements.add-payment-modal.company-name') }}
                </dt>
                <dd class="col-sm-8">
                  {{ settlement.organization.address.companyName }}
                </dd>
                <dt class="col-sm-4">
                  {{ $t('customer.settlements.add-payment-modal.account-number') }}
                </dt>
                <dd class="col-sm-8">
                  {{ settlement.organization.accountNumber }}
                </dd>
                <dt class="col-sm-4">
                  {{ $t('customer.settlements.add-payment-modal.payment-title') }}
                </dt>
                <dd class="col-sm-8">
                  {{ getPaymentTitle(settlement) }}
                </dd>
                <dt class="col-sm-4">
                  {{ $t('customer.settlements.add-payment-modal.amount') }}
                </dt>
                <dd class="col-sm-8">
                  <b-input-group
                    append="zł"
                  >
                    <b-form-input
                      v-model="settlement.paymentAmount"
                      step="0.01"
                      type="number"
                      class="input-group-merge text-right"
                      placeholder="50.00"
                      autocomplete="off"
                      min="1"
                      max="9999"
                    />
                  </b-input-group>
                </dd>
              </dl>
            </b-col>

            <b-col
              v-if="getQrCodeContent(settlement)"
              cols="12"
              md="5"
              class="text-center mt-1"
            >
              <div class="p-1 pb-50 mb-50 bg-white rounded d-inline-block">
                <qrcode-vue
                  :value="getQrCodeContent(settlement)"
                  :size="150"
                  level="M"
                />
              </div>
              <p class="font-small-2">
                {{ $t('customer.settlements.add-payment-modal.qr-description') }}
              </p>
            </b-col>
          </b-row>
        </b-modal>

      </b-col>
    </b-row>
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'
import QrcodeVue from 'qrcode.vue'
import settlementsSetup from '@/views/customer/settlements/settlementsSetup'
import RcOverlay from '@/layouts/components/rc/RcOverlay'
import BalanceHistoryTable from '@/views/_global/settlements/BalanceHistoryTable'
import role from '@/auth/role'

export default {
  components: { RcOverlay, BalanceHistoryTable, QrcodeVue },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      role,
    }
  },
  computed: {
    me() {
      return this.$store.getters['auth/getUser']
    },
  },
  setup: settlementsSetup,
}
</script>

<style lang="scss">
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
.b-overlay {
  position: relative !important;
  min-height: 100px;
}
</style>
